import axios from 'axios';
import Links from 'src/constant/Links';

let accessTokenId = -1;
let tenantNameId = -1;
const Api = {
    putAccessTokenInfo(accessToken: any) {
        accessTokenId !== -1 && axios.interceptors.request.eject(accessTokenId);
        accessTokenId = axios.interceptors.request.use(
            (config: any) => {
                config.headers['Authorization'] = `Bearer ${accessToken}`;
                config.headers['Content-Type'] = 'application/json';
                return config;
            },
            function (error) {
                return Promise.reject(error);
            },
        );
    },

    putTenantNameInfo(tenantName: any) {
        tenantNameId !== -1 && axios.interceptors.request.eject(tenantNameId);
        tenantNameId = axios.interceptors.request.use(
            (config: any) => {
                config.headers['X-TENANT-NAME'] = `${tenantName}`;
                return config;
            },
            function (error) {
                return Promise.reject(error);
            },
        );
    },

    getTenantsName(baseUrl: string, endpoint: string) {
        baseUrl = baseUrl || Links.BASE_URL_WITHOUT_TENANT;
        return new Promise((resolve, reject) => {
            axios
                .get(baseUrl + endpoint)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    },

    getPromise(baseUrl: string, endpoint: string) {
        return new Promise((resolve, reject) => {
            axios
                .get(baseUrl + endpoint)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    },

    getPromiseWithParams(baseUrl: string, endpoint: string, params: any) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                params: params,
                url: baseUrl + endpoint,
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    },

    postPromise(baseUrl: string, endpoint: string, data: any) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                data: data,
                url: baseUrl + endpoint,
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    },

    postPromiseWithoutData(baseUrl: string, endpoint: string) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'POST',
                url: baseUrl + endpoint,
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    },

    getPromiseWithUrl(baseUrl: string, endpoint: string, link: string) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: baseUrl + endpoint + link,
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    },

    deletePromise(baseUrl: string, endpoint: string, data: any) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'DELETE',
                data: data,
                url: baseUrl + endpoint,
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    },

    putPromise(baseUrl: string, endpoint: string, data: any) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'PUT',
                data: data,
                url: baseUrl + endpoint,
            })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    },
};
export default Api;
