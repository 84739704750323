import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';

/* ***Layouts**** */
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

/* ****Pages***** */
const Error = Loadable(lazy(() => import('../views/errorPages/Error404Page/Error')));
const Landingpage = Loadable(lazy(() => import('../views/landingPage/LandingPage')));

const PricingPage = Loadable(lazy(() => import('../views/subscriptionsPage/SubscriptionsPage')));

const UnProtectedRoutes = [
    {
        path: '/',
        element: <BlankLayout />,
        children: [
            {
                path: '/landingpage',
                element: <Landingpage />,
            },

            {
                path: '/subscriptions',
                element: <PricingPage />,
            },
            {
                path: '/',
                element: <Navigate to="/landingpage" />,
            },
        ],
    },
    {
        path: '/auth',
        element: <BlankLayout />,
        children: [{ path: '404', element: <Error /> }],
    },
    {
        path: '*',
        element: <Navigate to="/landingpage" />,
    },
];

export default UnProtectedRoutes;
