interface AppState {
    activeDir?: string;
    activeMode?: string;
    activeTheme?: string;
    MiniSidebarWidth?: number;
    TopBarHeight?: number;
    isCollapse?: boolean;
    isLayout?: string;
    isSidebarHover?: boolean;
    isMobileSidebar?: boolean;
    isHorizontal?: boolean;
    isLanguage?: string;
    isCardShadow?: boolean;
    borderRadius?: number;
    user?: any;
    userData?: any;
}

const initialAppState: AppState = {
    activeDir: 'ltr',
    activeMode: 'light', // This can be light or dark
    activeTheme: 'BLUE_THEME', // BLUE_THEME, GREEN_THEME, BLACK_THEME, PURPLE_THEME, ORANGE_THEME
    MiniSidebarWidth: 87,
    TopBarHeight: 70,
    isLayout: 'boxed', // This can be full or boxed
    isCollapse: false, // to make sidebar Mini by default
    isSidebarHover: false,
    isMobileSidebar: false,
    isHorizontal: false,
    isLanguage: 'en',
    isCardShadow: true,
    borderRadius: 7,
    user: null,
    userData: null,
};

export function userReducer(state = initialAppState, action: any) {
    switch (action.type) {
        case 'setUser':
            return { ...state, user: action.payload };
        case 'setUserData':
            return { ...state, userData: action.payload };
        case 'logout':
            return { ...state, user: null, userInfo: null };
        default:
            return state;
    }
}
export function customizerReducer(state = initialAppState, action: any) {
    switch (action.type) {
        case 'setDarkMode':
            switch (state.activeMode) {
                case 'light':
                    return { ...state, activeMode: 'dark' };
                case 'dark':
                    return { ...state, activeMode: 'light' };
                default:
                    return state;
            }
        case 'toggleSideBar':
            return { ...state, isCollapse: !state.isCollapse };
        case 'hoverSideBar':
            return { ...state, isSidebarHover: action.payload };
        case 'toggleMobileSideBar':
            return { ...state, isMobileSidebar: !state.isMobileSidebar };
        default:
            return state;
    }
}


