import _ from 'lodash';
import { createTheme } from '@mui/material/styles';
import { useSelector } from 'src/store/Store';
import { useEffect } from 'react';
import { AppState } from '../store/Store';
import components from './Components';
import typography from './Typography';
import { shadows, darkshadows } from './Shadows';
import { DarkThemeColors } from './DarkThemeColors';
import { LightThemeColors } from './LightThemeColors';
import { baseDarkTheme, baselightTheme } from './DefaultColors';
import * as locales from '@mui/material/locale';
import { useKeycloak } from '@react-keycloak/web';

export const BuildTheme = (config: any = {}) => {
    const { keycloak } = useKeycloak();
    const isLoggedIn = keycloak.authenticated;

    const themeOptions = LightThemeColors.find((theme) => theme.name === config.theme);
    const darkthemeOptions = DarkThemeColors.find((theme) => theme.name === config.theme);
    const customizer: any = useSelector((state: AppState) => state.customizer);
    const defaultTheme = isLoggedIn
        ? customizer.activeMode === 'dark'
            ? baseDarkTheme
            : baselightTheme
        : baselightTheme;
    const defaultShadow = isLoggedIn
        ? customizer.activeMode === 'dark'
            ? darkshadows
            : shadows
        : shadows;
    const themeSelect = isLoggedIn
        ? customizer.activeMode === 'dark'
            ? darkthemeOptions
            : themeOptions
        : themeOptions;
    const baseMode = {
        palette: {
            mode: isLoggedIn ? customizer.activeMode : 'light',
        },
        shape: {
            borderRadius: customizer.borderRadius,
        },
        shadows: defaultShadow,
        typography: typography,
    };
    const theme = createTheme(
        _.merge({}, baseMode, defaultTheme, locales, themeSelect, {
            direction: config.direction,
        }),
    );
    theme.components = components(theme);

    return theme;
};

const ThemeSettings = () => {
    const activDir = useSelector((state: any) => state.customizer.activeDir);
    const activeTheme = useSelector((state: any) => state.customizer.activeTheme);
    const theme = BuildTheme({
        direction: activDir,
        theme: activeTheme,
    });
    useEffect(() => {
        document.dir = activDir;
    }, [activDir]);

    return theme;
};

export { ThemeSettings };
