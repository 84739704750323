import { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import Api from 'src/api/index';

const KeyCloakTokenUpdate = () => {
    const { keycloak } = useKeycloak();
    const isLoggedIn = keycloak.authenticated;

    useEffect(() => {
        if (isLoggedIn) {
            Api.putAccessTokenInfo(keycloak.token);
        }
    }, [isLoggedIn, keycloak]);

    return null;
};

export default KeyCloakTokenUpdate;
