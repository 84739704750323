import Links from 'src/constant/Links';
import Api from '../index';

const TracApi = {
    getAccountApiKeys(url: any) {
        return Api.getPromiseWithUrl(Links.BASE_URL_WITHOUT_TENANT, '/v2/accounts/', url);
    },
    getAccountUsage(url: any) {
        return Api.getPromiseWithUrl(Links.BASE_URL_WITHOUT_TENANT, '/v2/accounts/', url);
    },

    postCreateApiKey(url: any, data: any) {
        return Api.postPromise(Links.BASE_URL_WITHOUT_TENANT, url, data);
    },
    postDeleteApiKey(url: any, data: any) {
        return Api.deletePromise(Links.BASE_URL_WITHOUT_TENANT, url, data);
    },

    getAccountCallHistory(url: any) {
        return Api.getPromiseWithUrl(Links.BASE_URL_WITHOUT_TENANT, '/v2/accounts/', url);
    },

    getWallets(url: any, params: any) {
        return Api.getPromiseWithParams(Links.BASE_URL_WITHOUT_TENANT, url, params);
    },

    getJobs(url: any, params: any) {
        return Api.getPromiseWithParams(Links.BASE_URL_WITHOUT_TENANT, url, params);
    },

    getAccount(url: any) {
        return Api.getPromiseWithUrl(Links.BASE_URL_WITHOUT_TENANT, '/v2/accounts/', url);
    },
    getWalletSources() {
        return Api.getPromise(Links.BASE_URL_WITHOUT_TENANT, '/v2/wallets/sources');
    },

    createWallet(url: any, data: any) {
        return Api.postPromise(Links.BASE_URL_WITHOUT_TENANT, url, data);
    },

    updateAccountName(account_id: any, data: any) {
        return Api.postPromise(
            Links.BASE_URL_WITHOUT_TENANT,
            `/v2/accounts/${account_id}/RenameAccount `,
            data,
        );
    },
    postCloseWelcomeDialog() {
        return Api.postPromiseWithoutData(
            Links.BASE_URL_WITHOUT_TENANT,
            '/v2/user/WelcomeMessageSeen',
        );
    },
    getSingleWallet(url: any) {
        return Api.getPromiseWithUrl(Links.BASE_URL_WITHOUT_TENANT, '/v2/accounts/', url);
    },
    searchJobs(url: any, params: any) {
        return Api.getPromiseWithParams(Links.BASE_URL_WITHOUT_TENANT, url, params);
    },
    createJob(url: any, data: any) {
        return Api.postPromise(Links.BASE_URL_WITHOUT_TENANT, url, data);
    },
    getSingleWalletBalance(url: any) {
        return Api.getPromiseWithUrl(Links.BASE_URL_WITHOUT_TENANT, '/v2/accounts/', url);
    },
    deleteWallet(url: any, data: any = {}) {
        return Api.deletePromise(Links.BASE_URL_WITHOUT_TENANT, url, data);
    },
    updateWallet(url: any, data: any) {
        return Api.putPromise(Links.BASE_URL_WITHOUT_TENANT, url, data);
    },
    subscribe(accountId: string, data: any) {
        return Api.postPromise(
            Links.BASE_URL_WITHOUT_TENANT,
            `/v2/accounts/${accountId}/Subscribe`,
            data,
        );
    },
    getPricingData() {
        return Api.getPromise(Links.BASE_URL_WITHOUT_TENANT, `/v2/pricing`);
    },
};

export default TracApi;
