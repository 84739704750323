import { FC } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { ThemeSettings } from './theme/Theme';
import ScrollToTop from './components/shared/ScrollToTop';
import Router from './routes/Router';
import { useKeycloak } from '@react-keycloak/web';
import UnProtectedRoutes from './routes/UnProtectedRoutes';
import KeyCloakTokenUpdate from './helpers/KeycloackUpdateToken';
import WelcomeDialog from 'src/components/dialogs/WelcomeDialog';
import { Toaster } from 'react-hot-toast';
import { useQuery } from '@tanstack/react-query';
import UserApi from './api/tracApi/userApi';
import { useDispatch } from './store/Store';

const App: FC = () => {
    const { keycloak } = useKeycloak();
    const isLoggedIn = keycloak.authenticated;
    const dispatch = useDispatch();
    const { data: userData } = useQuery({
        queryKey: ['userDataToWriteStorage'],
        enabled: isLoggedIn,
        staleTime: 0,
        queryFn: UserApi.getUser,
        select: (data: any) => data.result,
        onSuccess: (data) => {
            dispatch({
                type: "setUserData",
                payload: data
            });
        }
    });
    const keycloakToken = keycloak.tokenParsed;
    keycloakToken &&
        dispatch({
            type: 'setUser',
            payload: {
                name: keycloakToken.given_name,
                surname: keycloakToken.family_name,
                email: keycloakToken.email,
            },
        });
    const routing = useRoutes(isLoggedIn ? Router : UnProtectedRoutes);
    const theme = ThemeSettings();

    return (
        <ThemeProvider theme={theme}>
            <KeyCloakTokenUpdate />
            <CssBaseline />
            {isLoggedIn && userData?.info.show_welcome_message && <WelcomeDialog />}
            <Toaster />
            <ScrollToTop>{routing}</ScrollToTop>
        </ThemeProvider>
    );
};

export default App;
