import { useState } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Typography,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import TracApi from 'src/api/tracApi/tracApi';
import { useNavigate } from 'react-router';

const WelcomeDialog = () => {
    const [open, setOpen] = useState(true);
    const [checked, setChecked] = useState(false); // [checked, setChecked
    const navigate = useNavigate();

    const handleClose = () => {
        if (checked) {
            changeFlagMutation.mutate();
        }
        navigate('/getting-started');
        setOpen(false);
    };

    const handleCheckboxChange = (event: any) => {
        setChecked(event.target.checked);
    };

    const changeFlagMutation = useMutation({
        mutationFn: TracApi.postCloseWelcomeDialog,
    });
    return (
        <>
            <Dialog open={open} fullWidth maxWidth={'md'} onClose={handleClose}>
                <DialogTitle>Welcome !</DialogTitle>
                <DialogContent>
                    <Typography>
                        <b>CIS TRA</b>nsaction <b>C</b>ollection (CIS TRAC for short) is an API
                        service that makes it simple to access balance & transaction data for
                        wallets across multiple blockchains & centralized exchanges You can add any
                        number of wallets to your TRAC account and for each wallet you can:
                        <br />
                        <br />
                    </Typography>

                    <Typography>
                        {'>'} Fetch the current balance for all assets in the wallet
                        <br />
                    </Typography>
                    <Typography>
                        {'>'} Create a job to build / synchronize transaction history (the data is
                        stored for you)
                        <br />
                    </Typography>
                    <Typography>
                        {'>'} Access stored transaction history
                        <br />
                    </Typography>
                    <Typography>
                        {'>'} Access data in raw and normalized format
                        <br />
                        <br />
                    </Typography>
                    <Typography>
                        <b>Getting started</b>
                        <br />
                        <br />
                    </Typography>
                    <Typography>
                        Getting started is easy, click{' '}
                        {/* <Link href="/getting-started" onClick={handleClose}> */}
                        get started button below
                        {/* </Link>{' '} */} for our step by step guide.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ ml: 2, mr: 8 }} onClick={handleClose}>
                        Get Started
                    </Button>
                    <FormControlLabel
                        control={<Checkbox />}
                        label="Don't Show The Message Again"
                        onChange={handleCheckboxChange}
                    />

                    <div style={{ flex: '1 0 0' }} />
                </DialogActions>
            </Dialog>
        </>
    );
};

export default WelcomeDialog;
