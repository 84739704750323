import { createContext, useState } from 'react';

interface ISelectedAccountContext {
    account_name: string;
    account_id: string;
}

export const SelectedAccountContext = createContext({});

function SelectedAccountProvider({ children }: any) {
    const [selectedAccount, setSelectedAccount] = useState<ISelectedAccountContext>({
        account_name: '',
        account_id: '',
    });

    return (
        <SelectedAccountContext.Provider value={{ selectedAccount, setSelectedAccount }}>
            {children}
        </SelectedAccountContext.Provider>
    );
}

export default SelectedAccountProvider;
