import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
    TypedUseSelectorHook,
    useDispatch as useAppDispatch,
    useSelector as useAppSelector,
} from 'react-redux';
import { customizerReducer, userReducer } from "./Reducers";




/* Persist Storage */
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

const persistConfig = {
    key: 'cis-trac-root',
    storage,
};
//user info stored in local storage previously. to remove it from local storage:
localStorage.removeItem('persist:root');
const persistCustomizerReducer = persistReducer(persistConfig, customizerReducer);

const rootReducer = combineReducers({
    customizer: persistCustomizerReducer,
    user: userReducer,
});
export const store = configureStore({
    reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppState = ReturnType<typeof rootReducer>;
export const useDispatch = () => useAppDispatch<typeof store.dispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;
export const persistor = persistStore(store);
