import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { persistor, store } from './store/Store';
import Spinner from './helpers/spinner/Spinner';
import './utils/i18n';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloack';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import SelectedAccountProvider from './context/AccountInfoContext';
import { PersistGate } from 'redux-persist/integration/react';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 1,
        },
    },
});
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <ReactKeycloakProvider
        authClient={keycloak}
        LoadingComponent={<Spinner />}
        initOptions={{ checkLoginIframe: false }}
    >
        <QueryClientProvider client={queryClient}>
            <SelectedAccountProvider>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <Suspense fallback={<Spinner />}>
                            <BrowserRouter>
                                <App />
                            </BrowserRouter>
                        </Suspense>
                    </PersistGate>
                </Provider>
            </SelectedAccountProvider>
        </QueryClientProvider>
    </ReactKeycloakProvider>,
);
