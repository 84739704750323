import Keycloak from 'keycloak-js';

const keycloak = new Keycloak({
    url: 'https://sso.cryptoindexseries.com/auth',
    realm: 'CIS',
    clientId:
        process.env.NODE_ENV === 'development' ? 'cis_trac_dashboard_dev' : 'cis_trac_dashboard',
});

export default keycloak;
