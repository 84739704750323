import Links from 'src/constant/Links';
import Api from '../index';

const UserApi = {
    getUser() {
        return Api.getTenantsName(Links.BASE_URL_WITHOUT_TENANT, '/v2/user');
    },
};

export default UserApi;
