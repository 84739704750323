import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

/* ****Pages***** */
const Error404 = Loadable(lazy(() => import('../views/errorPages/Error404Page/Error')));
const Error401 = Loadable(lazy(() => import('../views/errorPages/Error401Page/Error401')));
const ApiPage = Loadable(lazy(() => import('../views/apiPage/ApiPage')));
const WalletsAccountsPage = Loadable(
    lazy(() => import('../views/walletsPage/WalletsAccountsPage')),
);
const AccountPage = Loadable(lazy(() => import('../views/accountPage/AccountPage')));
const AdminPage = Loadable(lazy(() => import('../views/adminPage/AdminPage')));
const AdminUserPage = Loadable(lazy(() => import('../views/adminPage/children/userPage/UserInfo')));
const AdminAccountPage = Loadable(
    lazy(() => import('../views/adminPage/children/accountPage/AccountInfo')),
);
const JobsPage = Loadable(lazy(() => import('../views/jobsPage/JobsPage')));
const ContactSupportPage = Loadable(
    lazy(() => import('../views/contactSupportPage/ContactSupportPage')),
);
const SingleWalletPage = Loadable(lazy(() => import('../views/singleWalletPage/SingleWallet')));
const GettingStartedPage = Loadable(
    lazy(() => import('../views/gettingStartedPage/GettingStartedPage')),
);
const GoogleSheetsPage = Loadable(lazy(() => import('../views/googleSheetsPage/GoogleSheetsPage')));

const PricingPage = Loadable(lazy(() => import('../views/subscriptionsPage/SubscriptionsPage')));

const Router = [
    {
        path: '/',
        element: <FullLayout />,
        children: [
            { path: '/', element: <Navigate to="/wallets" /> },
            {
                path: 'wallets',
                children: [
                    { index: true, element: <WalletsAccountsPage /> },
                    { path: ':walletId', element: <SingleWalletPage /> },
                ],
            },

            { path: '/api', element: <ApiPage /> },
            { path: '/account', element: <AccountPage /> },
            {
                path: '/admin',
                children: [
                    { index: true, element: <AdminPage /> },
                    { path: 'user/:user_id', element: <AdminUserPage /> },
                    { path: 'account/:account_id', element: <AdminAccountPage /> },
                ],
            },
            { path: '/jobs', element: <JobsPage /> },
            { path: '/contact-support', element: <ContactSupportPage /> },
            { path: '/getting-started', element: <GettingStartedPage /> },
            { path: '/sheets', element: <GoogleSheetsPage /> },
            { path: '/subscriptions', element: <PricingPage /> },
            { path: '/subscriptions/:success', element: <PricingPage /> },
            { path: '/401', element: <Error401 /> },
        ],
    },
    {
        path: '/auth',
        element: <BlankLayout />,
        children: [{ path: '404', element: <Error404 /> }],
    },
    {
        path: '*',
        element: <Navigate to="/wallets" />,
    },
];

export default Router;
